/* @import '~mdb-ui-kit/css/mdb.min.css'; */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap');
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.cascading-right {
  margin-right: 0px;
  margin-left: -400px;
}

@media (max-width: 991.98px) {
  .cascading-right {
    margin-right: 0;
    margin-left: 0;
  }
}

.reveal{
  position: relative;
  transform: translateY(150px);
  opacity: 0;
  transition: 1s all ease;
}

.reveal.active{
  transform: translateY(0);
  opacity: 1;
}

.card:hover {
  transform: scale(1.05);
  transition: transform 0.3s ease;
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

.slideInLeft {  
  animation: 1s ease-out 0s 1 slideInFromLeft;
  padding: 30px;
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

.slideInRight {  
  animation: 1s ease-out 0s 1 slideInFromRight;
  padding: 30px;
}

.button-pop-out {
  transition: transform 0.2s ease;
}

.button-pop-out:hover {
  transform: scale(1.1);
}

.navbar-toggler-icon {
  background-image: url("../src/images/list.svg") !important;
}

.custom-close-button {
  position: absolute;
  right: 10px; /* Adjust the value to fine-tune the button's position */
  top: 10px; /* Add a top value to align it properly */
  z-index: 1;
  background-color: transparent; /* Remove any background color */
  border: none; /* Remove any border */
}
